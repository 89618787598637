import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminPage from "./components/pages/AdminPage";
import ConnectAdmin from "./components/pages/ConnectAdmin";
import Contact from "./components/pages/Contact";
import SinglePage from "./components/pages/SinglePage";
import UnprivateRoutes from "./utils/UnprivateRoutes";
import PrivateRoutes from "./utils/PrivateRoutes";
import ChangePwd from "./components/pages/ChangePwd";
import Properties from "./components/pages/Properties";
import Property from "./components/pages/Property";
import Gestion from "./components/pages/Gestion";

const App = () => {
  return (
    <Routes>
      <Route path="*" element={<SinglePage />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/properties" element={<Properties />} />
      <Route path="/management" element={<Gestion />} />
      <Route path="/property/:id" element={<Property />} />
      <Route path="/password/reset/:id/:token" element={<ChangePwd />} />
      <Route path="/admin">
        <Route
          index
          element={
            <PrivateRoutes>
              <AdminPage />
            </PrivateRoutes>
          }
        />
        <Route
          path="login"
          element={
            <UnprivateRoutes>
              <ConnectAdmin />
            </UnprivateRoutes>
          }
        />
      </Route>
    </Routes>
  );
};

export default App;
