import { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';

import RUG from "react-upload-gallery";

const FormProperty = ({ property, removeShow, update }) => {
  const [details, setDetails] = useState({
    price: property?.price ?? "",
    street: property?.street ?? "",
    zip: property?.zip ?? "",
    city: property?.city ?? "",
    number: property?.number ?? "",
    boxNumber: property?.boxNumber ?? 0,
    nbBedrooms: property?.nbBedrooms ?? "",
    nbBathrooms: property?.nbBathrooms ?? "",
    outdoorsArea: property?.outdoorsArea ?? "0",
    indoorsArea: property?.indoorsArea ?? "",
    isHouse: property?.isHouse ?? true,
    isLocation: property?.isLocation ?? false,
    peb: property?.peb ?? "inconnu",
    charges: property?.charges ?? 0,
    description: property?.description ?? "",
    title: property?.title ?? "",
    images: property?.images ?? [],
  });

  const [errors, setErrors] = useState({
      description:"", 
      title:"", 
      zip:"", 
      city:"", 
      nbBedrooms:"", 
      indoorsArea:"", 
      peb:"", 
      price:"" 
  })

  const handleSubmit = (e) => {
    e.preventDefault();

    if (property) {
      axios
        .put(`${process.env.REACT_APP_SERVER_URL}/property/${property._id}`, details)
        .then((res) => {
          removeShow();
          update(true);
          toast.success(res.data.message, {className:"toast-message"});
        })
        .catch((err) => {
          if(err.response.data.message instanceof Object) {
            toast.error('Certaines données sont manquantes', {className:"toast-message"})
            setErrors({...errors, ...err.response.data.message})
            update(true);
          } else { 
            toast.error(err.response.data.message, {className:"toast-message"})
            setErrors({
              description:"", 
              title:"", 
              zip:"", 
              city:"", 
              nbBedrooms:"", 
              indoorsArea:"", 
              peb:"", 
              price:"" 
          })
          }
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/property/`, details)
        .then((res) => {
          removeShow();
          update(true);
        })
        .catch((err) => {
          if(err.response.data.message instanceof Object) {
            toast.error('Certaines données sont manquantes', {className:"toast-message"})
            setErrors({...errors, ...err.response.data.message})
          } else { 
            toast.error(err.response.data.message, {className:"toast-message"})
            setErrors({
              description:"", 
              title:"", 
              zip:"", 
              city:"", 
              nbBedrooms:"", 
              indoorsArea:"", 
              peb:"", 
              price:"" 
          })
          }
        });

    };
  }


  const handleChange = (e) => {
    let newVal = e.target.value;
    if (e.target.value === "true") newVal = true;
    if (e.target.value === "false") newVal = false;
    setDetails({ ...details, [e.target.name]: newVal });
  };

  const setInitialState = (images) => {
    let initialsImages = [];
    images.forEach(image => {
      initialsImages.push({source: `${process.env.REACT_APP_SERVER_URL}/${property._id}/${image}`, name: image})
    });

    return initialsImages;
  }

  const addImage = async (image) => {
    setDetails({ ...details, images: [...details.images, image]});
    await axios.put(`${process.env.REACT_APP_SERVER_URL}/property/${property._id}`, {images:[...details.images, image]});
    update(true);
  }

  const removeImage = async (image) => {
    setDetails({...details, images: details.images.filter(img => img !== image)})
    await axios.delete(`${process.env.REACT_APP_SERVER_URL}/property/pictures${property?'/'+property._id:'/temp'}/${image}`);
    await axios.put(`${process.env.REACT_APP_SERVER_URL}/property/${property._id}`, {images:details.images.filter(img => img !== image)});
    update(true);
  }

  const onSort = async (images) => {
    setDetails({...details, images: images.map(img => img.name)});
    await axios.put(`${process.env.REACT_APP_SERVER_URL}/property/${property._id}`, {images:images.map(img => img.name)});
    update(true);
  }

  const hideShow = () => {
    removeShow();
    if(!property) {
      axios.get(`${process.env.REACT_APP_SERVER_URL}/property/temp`)
        .catch(() => toast.error("Le bien n'a pas été créé", {className:"toast-message"}));
    }
  }

  return (
    <div className="form-property" onClick={() =>  hideShow()}>
      <div className="form-container" onClick={(e) => e.stopPropagation()}>
        <div className="form-container__files">
        <RUG
          action={`${process.env.REACT_APP_SERVER_URL}/property/pictures/${property?property._id:''}`}
          source={(response) => addImage(response.url.split('/').pop())}
          initialState={ property ? setInitialState(property.images) : []}
          onDeleted={(img) => { removeImage(img.name) }}
          onSortEnd={(images) => onSort(images)}
        />
        </div>
        <div className="form-container__data">
          <h3>{property ? "MODIFICATION" : "AJOUT"} D'UNE PROPRIÉTÉ</h3>
          <form onSubmit={handleSubmit} className="form">
            <div className="form__group form__description">
              <label htmlFor="title">Titre*</label>
              <input
                type='text'
                  name="title"
                  className={errors.title==='missing' ? "input missing" : "input"}
                  onChange={handleChange}
                  value={details.title}
                />
              <label htmlFor="description">Description*</label>
              <textarea
                  type="text"
                  rows={3}
                  name="description"
                  className={errors.description==='missing' ? "input description missing" : "input description"}
                  onChange={handleChange}
                  value={details.description}
                />
            </div>
            <div className="form__group form__type">
              <div>
                <label htmlFor="isHouse">Type de bien</label>
                <div className="form__type__radioButton">
                  <div>
                    <input
                      type="radio"
                      name="isHouse"
                      value={true}
                      checked={details.isHouse}
                      onChange={handleChange}
                    />
                    <div>Maison</div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="isHouse"
                      value={false}
                      checked={!details.isHouse}
                      onChange={handleChange}
                    />
                    <div>Appartement</div>
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="isLocation">Type d'acquisition</label>
                <div className="form__type__radioButton">
                  <div>
                    <input
                      type="radio"
                      name="isLocation"
                      checked={!details.isLocation}
                      onChange={handleChange}
                      value={false}
                    />
                    <div>Vente</div>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="isLocation"
                      checked={details.isLocation}
                      onChange={handleChange}
                      value={true}
                    />
                    <div>Location</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form__group form__price">
              <div>
                <label htmlFor="price">Prix*</label>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className={errors.price==='missing' ? "input missing" : "input"}
                  onChange={handleChange}
                  value={details.price}
                />
              </div>
              <div>
                <label htmlFor="charges">Charges</label>
                <input
                  type="number"
                  name="charges"
                  className="input"
                  onChange={handleChange}
                  value={details.charges}
                />
              </div>
            </div>
            <div className="form__group form__address">
              <div className="form__address__group">
                <div className="form__address__street">
                  <label htmlFor="street">Adresse</label>
                  <input
                    type="text"
                    name="street"
                    id="street"
                    className="input"
                    onChange={handleChange}
                    value={details.street}
                  />
                </div>
              </div>
              <div className="form__address__group">
                <div> 
                  <label htmlFor="number">Numéro</label>
                  <input
                    type="number"
                    name="number"
                    id="number"
                    className="input"
                    onChange={handleChange}
                    value={details.number}
                  />
                </div>
                <div>
                  <label htmlFor="boxNumber">Boite</label>
                  <input
                    type="number"
                    name="boxNumber"
                    id="boxNumber"
                    className="input"
                    onChange={handleChange}
                    value={details.boxNumber}
                  />
                </div>
              </div>
              <div className="form__address__group">
                <div>
                  <label htmlFor="zip">Code postal*</label>
                  <input
                    type="number"
                    name="zip"
                    id="zip"
                    className={errors.zip==='missing' ? "input missing" : "input"}
                    onChange={handleChange}
                    value={details.zip}
                  />
                </div>
                <div>
                  <label htmlFor="city">Ville*</label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    className={errors.city==='missing' ? "input missing" : "input"}
                    onChange={handleChange}
                    value={details.city}
                  />
                </div>
              </div>
            </div>
            <div className="form__group form__interior">
              <div>
                <label htmlFor="nbBedrooms">Nombre de chambres*</label>
                <input
                  type="number"
                  name="nbBedrooms"
                  id="nbBedrooms"
                  className={errors.nbBedrooms==='missing' ? "input missing" : "input"}
                  onChange={handleChange}
                  value={details.nbBedrooms}
                />
              </div>
              <div>
                <label htmlFor="nbBathrooms">Nombre de sdb</label>
                <input
                  type="number"
                  name="nbBathrooms"
                  id="nbBathrooms"
                  className="input"
                  onChange={handleChange}
                  value={details.nbBathrooms}
                />
              </div>
              <div>
                <label htmlFor="outdoorsArea">Surface extérieure (m²)*</label>
                <input
                  type="number"
                  name="outdoorsArea"
                  id="outdoorsArea"
                  className="input"
                  onChange={handleChange}
                  value={details.outdoorsArea}
                />
              </div>
              <div>
                <label htmlFor="indoorsArea">Surface intérieure (m²)*</label>
                <input
                  type="number"
                  name="indoorsArea"
                  id="indoorsArea"
                  className={errors.indoorsArea==='missing' ? "input missing" : "input"}
                  onChange={handleChange}
                  value={details.indoorsArea}
                />
              </div>
              <div className="form__interior__peb">
                <label htmlFor="peb">PEB</label>
                <select
                  type="number"
                  name="peb"
                  id="peb"
                  className="input"
                  onChange={handleChange}
                  value={details.peb}
                >
                  <option value="inconnu">Inconnu</option>
                  <option value="A++">A++</option>
                  <option value="A+">A+</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="E">E</option>
                  <option value="F">F</option>
                  <option value="G">G</option>
                </select>
              </div>
            </div>

            <button type="submit">{property ? "MODIFIER" : "CRÉER"}</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormProperty;
