import React from "react";
import location_svg from "./../assets/location.svg";
import bed_svg from "./../assets/bed.svg";
import { useNavigate } from "react-router-dom";

const MiniCard = ({ property }) => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/property/${property._id}`;
    navigate(path);
  };

  return (
    <div className="minicard" onClick={routeChange}>
      {(property.isDone || property.isUnderOption) && (
        <div className="property-card__ruban">
          {property.isDone
            ? property.isLocation
              ? "LOUÉ"
              : "VENDU"
            : "SOUS-OPTION"}
        </div>
      )}
      <div
        className="card_img_container"
        style={{
          backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/${property._id}/${property.images[0]}")`,
        }}
      ></div>
      <div className="card_infos">
        <div className="card_title">{property.title}</div>
        <div className="card_price">
          {property.price}€{" "}
          {property.charges && (
            <span>
              ( +{property.charges}€ {!property.isLocation && "/ mois"})
            </span>
          )}{" "}
          {property.isLocation && <span>/ mois</span>}
        </div>
        <div className="card_pics">
          <div className="card_bedrooms pics_infos">
            <img src={bed_svg} alt="beds number" className="bed-svg pic" />
            {property.nbBedrooms}
          </div>
          <div className="card_address  pics_infos">
            <img
              src={location_svg}
              alt="location"
              className="location-svg pic"
            />
            {property.zip} {property.city}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCard;
