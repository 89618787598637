import React from "react";
import Footer from "./Footer";
import Home from "./Home";
import Properties from "./Properties";
import Services from "./Services";
import NavBar from "./../NavBar";
import Principles from "./Principles";
import Gestion from "./Gestion";

const SinglePage = () => {
  return (
    <>
      <NavBar />
      <Home />
      <Principles />
      <Services />
      <Footer />
    </>
  );
};

export default SinglePage;
