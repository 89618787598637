import React, { useState, useEffect } from "react";
import Button from "../Button";
import home_video from "./../../assets/home_video.mp4";
import ios_image from "./../../assets/home_img.jpg";

const Home = () => {
  const [buttonSize, setButtonSize] = useState();

  useEffect(() => {
    const video = document.getElementById("home__video");
    const backgroundimage = document.getElementById("ios-image");
    video.playbackRate = 0.7;
    video.play()
    .catch((_) => {
      video.classList.add('hidden');
      backgroundimage.classList.remove('hidden');
    });
  }, []);

  useEffect(() => {
    showButton();
    window.addEventListener("resize", showButton);
  });

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButtonSize("btn--large");
    } else {
      setButtonSize("btn--wide");
    }
  };

  return (
    <div className="section" id="home">
      <div className="home">
        <div className="home__video-wrapper">
          <img id="ios-image" src={ios_image} className="home__video hidden" alt="Background"/>
          <video
            className="home__video"
            id="home__video"
            playsInline
            autoPlay
            muted
            loop
            data-wf-ignore="true"
            data-object-fit="cover"
            controls={false}
          >
            <source src={home_video} type="video/mp4" data-wf-ignore="true"/>
          </video>
        </div>
        <div className="home__info">
          <h2>
            Ne cherchez plus,
            <br /> vous êtes au bon endroit !
          </h2>
          <Button
            href="#services"
            buttonStyle={"btn--primary"}
            buttonColor={"btn--light"}
            buttonSize={buttonSize}
            children={"Découvrez nos services"}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
