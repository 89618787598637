import React from "react";
import { BsBookmarkCheck } from "react-icons/bs";

const Badge = ({ title, points, icon }) => {
  return (
    <div className="badge">
      <img width={90} src={icon} alt="" />
      <h3>{title}</h3>
      <div>
        {points.map((point, i) => {
          return (
            <p className="bulletPoint" key={i}>
              <span>
                <BsBookmarkCheck size={20} />
              </span>
              {point}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Badge;
