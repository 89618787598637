import { useState, useEffect } from "react";
import Slider from "react-slick";
import location_svg from "../../assets/location.svg";
import outdoor_svg from "../../assets/outdoor.svg";
import indoor_svg from "../../assets/indoor.svg";
import bed_svg from "../../assets/bed.svg";
import bath_svg from "../../assets/bath.svg";
import peb_svg from "../../assets/peb.svg";
import logo from "../../assets/logo.png";
import Toast from "../Toast";
import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { toast } from "react-toastify";
import axios from "axios";

const Property = () => {
  const [property, setProperty] = useState();
  const params = useParams();

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    swipeToSlide: false,
    swipe: false,
  };

  const fetchProperty = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/property/${params.id}`, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        res.data.property && setProperty(res.data.property);
      })
      .catch((err) => {
        toast.error(err.response.data.message, { className: "toast-message" });
      });
  };

  useEffect(() => {
    fetchProperty();
  }, []);

  return (
    <>
      <NavBar />
      <div className="property">
        {(property?.isDone || property?.isUnderOption) && (
          <div className="property__ruban">
            {property?.isDone
              ? property?.isLocation
                ? "LOUÉ"
                : "VENDU"
              : "SOUS-OPTION"}
          </div>
        )}
        {property?.images.length ? (
          <div className="property__img">
            <Slider {...settingsMain}>
              {property.images.map((slide, idx) => (
                <img
                  key={idx}
                  alt=""
                  className="slick-slide-image"
                  src={`https://api.newplace.be/${property._id}/${slide}`}
                />
              ))}
              <img
                key={"logo"}
                className="slick-slide-image"
                src={logo}
                alt=""
              />
            </Slider>
          </div>
        ) : (
          <div className="property__logo">
            <img className="property__logo-img" src={logo} alt="" />
          </div>
        )}
        <div className="property__data">
          <div className="data">
            <div className="data__title">{property?.title}</div>
            <div className="data__description">{property?.description}</div>

            <div className="data__price">
              {property?.price}€{" "}
              {property?.charges && (
                <span>
                  ( +{property?.charges}€ {!property?.isLocation && "/ mois"})
                </span>
              )}{" "}
              {property?.isLocation && <span>/ mois</span>}
            </div>
            <div className="data__infos">
              <div className="data__info">
                <div className="data__peb info-container">
                  <div className="svg-container">
                    <img src={peb_svg} alt="peb" className="peb-svg svg" />
                  </div>
                  {property?.peb}
                </div>
                <div className="data__bedrooms info-container">
                  <div className="svg-container">
                    <img
                      src={bed_svg}
                      alt="beds number"
                      className="bed-svg svg"
                    />
                  </div>
                  {property?.nbBedrooms}
                </div>

                {property?.outdoorsArea === 0 ? (
                  <div className="data__badrooms info-container">
                    <div className="svg-container">
                      <img
                        src={bath_svg}
                        alt="bathrooms number"
                        className="bath-svg svg"
                      />
                    </div>
                    {property?.nbBathrooms || 0}
                  </div>
                ) : (
                  <div className="data__outdoor info-container">
                    <div className="svg-container">
                      <img
                        src={outdoor_svg}
                        alt="outdoor area"
                        className="outdoor-svg svg"
                      />
                    </div>
                    {property?.outdoorsArea}m²
                  </div>
                )}
                <div className="data__indoor info-container">
                  <div className="svg-container">
                    <img
                      src={indoor_svg}
                      alt="indoor area"
                      className="indoor-svg svg"
                    />
                  </div>
                  {property?.indoorsArea}m²
                </div>
              </div>
              <div className="data__address info-container">
                <div className="svg-container">
                  <img
                    src={location_svg}
                    alt="location"
                    className="location-svg svg"
                  />
                </div>
                <p>
                  {property?.street} {property?.number}
                  {(property?.street || property?.number) && ","}{" "}
                  {property?.zip} {property?.city}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast />
    </>
  );
};

export default Property;
