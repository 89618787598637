import React from "react";
import NavBar from "../NavBar";
import Footer from "./Footer";
import Badge from "../badge";
import { FaStar } from "react-icons/fa";
import { BsBookmarkCheck, BsCheckCircle } from "react-icons/bs";
import private_property from "./../../assets/gestionImg.jpg";
import handshake from "./../../assets/handshake.gif";
import key from "./../../assets/key.gif";
import euro from "./../../assets/euro.gif";

const badgeCard = [
  {
    title: "Mise en location",
    points: [
      "Création d’un descriptif de location",
      "Commande d’un PEB",
      "Mise en valeur de votre bien avec photos + drone",
      "Pub et visites pour trouver les futurs locataires",
      "Analyse du candidat locataire - vérification solvabilité",
      "Rédaction et signature du bail",
      "Mise en place de l’assurance et garantie locative",
      "Gestion des contrats énergies",
      "état des lieux d’entrée",
      "Réception du 1er loyer",
      "Remise des clés",
      "Enregistrement du bail",
    ],
    icon: key,
  },
  {
    title: "Gestion technique et administrative",
    points: [
      "Intermédiaire entre le propriétaire et le locataire",
      "Les différents contrôles à réaliser (chaudière, assurance, précompte, etc)",
      "Assurance responsabilité couverte",
      "Création et sauvegarde de chaque dossier personnalisé dans le logiciel GL",
      "Gérer les contrats (baux, fournisseurs, ...)",
      "Expert, devis, travaux, primes, chantier",
      "(Gestion des sinistres *)",
      "(Suivi des travaux de rénovation * )",
    ],
    icon: handshake,
  },
  {
    title: "Gestion financière",
    points: [
      "Ouverture d’un compte bancaire rubriqué",
      "Encaissement et indexation des loyers",
      "Transfert mensuel des loyers",
      "Répartition des charges liées au propriétaire et au locataire",
      "(Gestion des contentieux *)",
    ],
    icon: euro,
  },
];

const Gestion = () => {
  return (
    <>
      <NavBar />
      <div className="section gestion">
        <h1>GESTION LOCATIVE</h1>
        <div className="advantages">
          <div className="text">
            <h2>Avantages</h2>
            <ul>
              <li>
                <span>
                  <BsCheckCircle />
                </span>
                Libérez du temps pour vous
              </li>
              <li>
                <span>
                  <BsCheckCircle />
                </span>
                Diminuez le risque de loyers impayés
              </li>
              <li>
                <span>
                  <BsCheckCircle />
                </span>
                Valorisation et optimisation de votre patrimoine
              </li>
              <li>
                <span>
                  <BsCheckCircle />
                </span>
                Tranquillité d’esprit
              </li>
            </ul>
          </div>

          <div className="img-wrapper">
            <img className="img" src={private_property} alt="" />
          </div>
        </div>
        <div className="working">
          <h2>Comment ça marche ?</h2>
          <div className="line"></div>
          <div className="badges">
            {badgeCard.map((badge, i) => {
              return (
                <Badge
                  title={badge.title}
                  points={badge.points}
                  icon={badge.icon}
                  key={i}
                />
              );
            })}
          </div>
        </div>
        <div className="formules">
          <h2>Formules</h2>
          <div className="line"></div>

          <div className="packs">
            <div className="packWrapper wrapper1">
              <div className="pack">
                <div className="star">
                  <FaStar size={50} color="#d7d7d7" />
                </div>
                <div className="header">
                  <h4>Pack Silver</h4>
                  <p>4% du loyer mensuel (min 30€) / lot</p>
                </div>
                <div className="core">
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Administrative
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Technique
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Financière
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Rapport Trimestriel
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Réduction des honoraires pour la remise en location (-10%)
                  </p>
                </div>
              </div>
            </div>

            <div className="packWrapper wrapper3">
              <div className="pack">
                <div className="star">
                  <FaStar size={50} color="#d1b000" />
                  <FaStar size={50} color="#d1b000" />
                </div>
                <div className="header">
                  <h4>Pack Gold</h4>
                  <p>6% du loyer mensuel (min 45€) / lot</p>
                </div>
                <div className="core">
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Administrative
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Technique
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Financière
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Interlocuteur unique et disponible sur gsm 6j/7
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Rapport Mensuel
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Contentieux
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Hotline Juridique
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Réduction des honoraires pour la remise en location (-20%)
                  </p>
                </div>
              </div>
            </div>
            <div className="packWrapper wrapper2">
              <div className="pack">
                <div className="star">
                  <FaStar size={50} color="#7df9ff" />
                  <FaStar size={50} color="#7df9ff" />
                  <FaStar size={50} color="#7df9ff" />
                </div>
                <div className="header">
                  <h4>Pack Premium</h4>
                  <p>8% du loyer mensuel (min 70€) / lot</p>
                </div>
                <div className="core">
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Administrative
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Technique
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Financière
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Interlocuteur unique et disponible sur gsm 7j/7
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Rapport Mensuel
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion Contentieux
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Hotline Juridique
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Réduction des honoraires pour la remise en location (-50%)
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Visite 1x/an sur place
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Gestion sinistre
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Suivi Travaux
                  </p>
                  <p className="bulletPoint">
                    <span>
                      <BsBookmarkCheck size={20} />
                    </span>
                    Calculs et conseils de Rentabilité
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gestion;
