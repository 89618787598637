import React, { useState } from 'react';
import Button from '../Button';
import NavBar from '../NavBar';
import bckg_map from './../../assets/bckg_section.png';
import info from './../../assets/info.gif'
import key from './../../assets/key.gif'
import home from './../../assets/home.gif'
import Footer from './Footer';
import { toast } from 'react-toastify';
import Toast from './../Toast';


const Contact = () => {

    const [formVisibility, setFormVisibility] = useState(false);
    const [status, setStatus] = useState("Envoyer");
    

    const [details, setDetails] = useState({
        object: '',
        name: '',
        surname: '',
        email: '',
        phone: ''
    })

    const handleChange = (e) => {
        let newVal = e.target.value;
        setDetails({ ...details, [e.target.name]: newVal });
    };

    const showForm = (object) => {
        setDetails({...details, object});
        setFormVisibility(true);
    }

    const hideForm = () => {
        setFormVisibility(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(details.name === '' || details.surname === '' || details.email === '') {
            toast.error('Certaines données sont manquantes', {className:"toast-message"});
            return
        }

        setStatus("Envoi...");

        await fetch(`${process.env.REACT_APP_SERVER_URL}/contact`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(details),
        }).then(() => {
            toast.success('Message envoyé', {className:"toast-message"});
            setStatus("Envoyer");
            setFormVisibility(false);
            
            setDetails({
                object: '',
                name: '',
                surname: '',
                email: '',
                phone: ''
            })
        });
        
    };

    return (
        <>
            <NavBar />
            <div className='section contact '>
                <img src={bckg_map} className='background-image contact-img' alt='' />
                <div className="contact__list">
                    <div className="contact__list-title">
                        <h2>Le choix pour votre  bien</h2>
                    </div>
                    <div className="contact__list-text">
                        Vous souhaitez plus d'informations ?<br/>
                        Remplissez ce formulaire et nous vous recontacterons dans les plus brefs délais.
                    </div>
                </div>
                <div className="contact__form-buttons">
                    <div className={formVisibility ? 'contact__right' : 'contact__right active'}>
                        <div className="contact__buttons">
                            <div onClick={() => showForm('Vente')} className='contact__button'>
                                <div className='contact__button-logo'>
                                    <img className='test' width={60} src={home} alt=''/>
                                </div>
                                <div className='contact__button-children'>Je veux vendre</div>
                            </div>
                            <div onClick={() => showForm('Location')} className='contact__button'>
                                <div className='contact__button-logo'>
                                    <img className='test' width={60} src={key} alt=''/>
                                </div>
                                <div className='contact__button-children'>Je veux louer</div>
                            </div>
                            <div onClick={() => showForm('Informations')} className='contact__button'>
                                <div className='contact__button-logo'>
                                    <img className='test' width={60} src={info} alt=''/>
                                </div>
                                <div className='contact__button-children'>Je veux des informations</div>
                            </div>
                        </div>
                    </div>
                    <form className={formVisibility ? 'contact__form active' : 'contact__form'}>
                        <div className='input'>
                            <label htmlFor="name">Nom *</label>
                            <input name='name' type="text" id="name" required onChange={handleChange} value={details.name} />
                        </div>
                        <div className='input'>
                            <label htmlFor="surname">Prénom *</label>
                            <input name='surname' type="text" id="surname" required onChange={handleChange} value={details.surname} />
                        </div>
                        <div className='input'>
                            <label htmlFor="email">Email *</label>
                            <input name='email' type="email" id="email" required onChange={handleChange} value={details.email} />
                        </div>
                        <div className='input'>
                            <label htmlFor="phone">Téléphone</label>
                            <input name='phone' type="tel" id="phone" onChange={handleChange} value={details.phone} />
                        </div>
                        <div className="buttons">
                            <Button onClick={() => hideForm()} buttonColor={'btn--light'} buttonStyle={'btn--secondary'} buttonSize={'btn--large'} children={'Retour'} style />
                            <Button onClick={(e) => handleSubmit(e)} buttonColor={'btn--light'} buttonStyle={'btn--secondary'} buttonSize={'btn--large'} children={status} />
                        </div>
                        <div className="contact__form-footer">
                            *Champs obligatoires
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
            <Toast />
            
        </>
    );
};

export default Contact;