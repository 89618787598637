import React from "react";
import Button from "../Button";
import bckg_map from "./../../assets/bckg_section.png";
import euro from "./../../assets/euro.gif";
import calculator from "./../../assets/calculator.gif";
import thumb from "./../../assets/thumbs-up.gif";
import handshake from "./../../assets/handshake.gif";

const Services = () => {
  return (
    <div className="section" id="services">
      <img src={bckg_map} className="background-image services-img" alt="" />
      <div className="services">
        <div className="col services__title">
          <h2>
            Ici, vous pouvez gagner
            <br /> 
            sur tous les tableaux
          </h2>
          <Button
            href="/contact"
            buttonColor={"btn--light"}
            buttonStyle={"btn--secondary"}
            buttonSize={"btn--large"}
            children={"Je veux vendre ou louer"}
          />
        </div>
        <div className="col services__info">
          <div className="service estimation">
            <img className="test" width={60} src={calculator} alt="" />
            <div>
              <h3>Estimation complète et gratuite</h3>
              <p>Car le prix est un élément essentiel de la vente.</p>
            </div>
          </div>
          <div className="service frame">
            <img className="test" width={60} src={handshake} alt="" />
            <div>
              <h3>Encadrement optimal</h3>
              <p>Un agent immobilier qui vous accompagne de A à Z.</p>
            </div>
          </div>
          <div className="service money">
            <img className="test" width={60} src={euro} alt="" />
            <div>
              <h3>Gain d'argent</h3>
              <p>Jusqu'à 3x moins cher qu'une agence classique.</p>
            </div>
          </div>
          <div className="service effectiveness">
            <img className="test" width={60} src={thumb} alt="" />
            <div>
              <h3>Rapidité et efficacité</h3>
              <p>Nous sommes disponibles 7j/7 ...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
