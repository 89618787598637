import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from './../Button';
import Toast from '../Toast';
import { toast} from 'react-toastify';
import axios from 'axios';

const ChangePwd = () => {

    const navigate = useNavigate();

    const {id, token} = useParams();
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if(password === passwordConfirm) {
            if(password.length < 6)
                if(password.length === 0)
                    toast.warning('Veuillez remplir les champs', {className:"toast-message"})
                else
                    toast.error('Le mot de passe est trop court (min 6 caractères)', {className:"toast-message"})
            else {
                axios.post(`${process.env.REACT_APP_SERVER_URL}/password/reset/${id}/${token}`, {password, passwordConfirm})
                    .then((res) => {
                        toast.success('Le mot de passe a été mis à jour', {className:"toast-message"});
                        setTimeout(() => {  navigate('/admin') }, 4000);})
                    .catch((err) => {
                        toast.error("Une erreur s'est produite", {className:"toast-message"})
                })
            }
        } else {
            toast.error('Les mots de passe ne sont pas identiques !', {className:"toast-message"})
        }
    }

    return (
        <div className='change-pwd'>
            <form>
                <div>
                    <label htmlFor="password">Nouveau mot de passe</label>
                    <input type="password" name="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                </div>
                <div>
                    <label htmlFor="password2">Confirmation de mot de passe</label>
                    <input type="password" name="password2" id="password2" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                </div>
                <Button buttonSize={'btn--mobile'} buttonStyle={'btn--secondary'} onClick={handleSubmit} children={'Réinitialiser'} />
            </form>
            <Toast />
        </div>
    );
};

export default ChangePwd;