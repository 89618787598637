import React from 'react';
import { ToastContainer } from 'react-toastify';

const Toast = () => {
    return (
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
    );
};

export default Toast;