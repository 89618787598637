import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function PrivateRoutes({children}) {

    const {isLoading, isAuthenticated} = useAuth();

    if(isLoading) return "Loading...";
    if(!isAuthenticated) return <Navigate to="/admin/login"/>;
    return children;
}