import Button from "./Button";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import location_svg from "./../assets/location.svg";
import outdoor_svg from "./../assets/outdoor.svg";
import indoor_svg from "./../assets/indoor.svg";
import bed_svg from "./../assets/bed.svg";
import bath_svg from "./../assets/bath.svg";
import peb_svg from "./../assets/peb.svg";
import axios from "axios";
import logo from "./../assets/logo.png";
import Toast from "./Toast";
import { toast } from "react-toastify";

const Card = ({ property, changeShow, update, confirm }) => {
  const [details, setDetails] = useState({
    isUnderOption: property?.isUnderOption,
    isDone: property?.isDone,
  });

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    swipeToSlide: false,
    swipe: false,
  };

  const edit = async (newDetails = details) => {
    await axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/property/${property._id}`,
        newDetails
      )
      .then((res) => {
        update(true);
        // toast.success(res.data.message, {className:"toast-message"})
      })
      .catch((err) => {
        toast.error("Une erreur s'est produite", {
          className: "toast-message",
        });
      });
  };

  const changeOption = () => {
    const newDetails = { ...details, isUnderOption: !details.isUnderOption };
    setDetails(newDetails);
    edit(newDetails);
  };

  const changeSold = () => {
    const newDetails = { ...details, isDone: !details.isDone };
    setDetails(newDetails);
    edit(newDetails);
  };

  return (
    <>
      <div className="property-card">
        {(details.isDone || details.isUnderOption) && (
          <div className="property-card__ruban">
            {details.isDone
              ? property.isLocation
                ? "LOUÉ"
                : "VENDU"
              : "SOUS-OPTION"}
          </div>
        )}
        {property.images.length ? (
          <div className="property-card__img">
            {/* <div> */}
            <Slider {...settingsMain}>
              {property.images.map((slide, idx) => (
                <img
                  key={idx}
                  alt=""
                  className="slick-slide-image"
                  src={`${process.env.REACT_APP_SERVER_URL}/${property._id}/${slide}`}
                />
              ))}
              <img
                key={"logo"}
                className="slick-slide-image"
                src={logo}
                alt=""
              />
            </Slider>
            {/* <div className="thumbnail-slider-wrap">
                            <Slider
                                {...settingsThumbs}
                                asNavFor={nav1}
                                ref={slider => (setSlider2(slider))}
                            >

                                {property.images.map((slide) =>

                                    <div className="slick-slide" key={slide.id}>
                                        <img alt="" className="slick-slide-image" src={`${process.env.REACT_APP_SERVER_URL}/${property._id}/${slide}`} />
                                    </div>

                                )}
                                <div className="slick-slide" key={property.images.length}>
                                    <img className="slick-slide-image" src={logo} alt="" />
                                </div>
                            </Slider>
                        </div> */}
            {/* </div> */}
          </div>
        ) : (
          <div className="property-card__logo">
            <img className="property-card__logo-img" src={logo} alt="" />
          </div>
        )}
        <div className="property-card__data">
          <div className="data">
            <div className="data__title">{property.title}</div>
            <div className="data__description">{property.description}</div>

            <div className="data__price">
              {property.price}€{" "}
              {property.charges && (
                <span>
                  ( +{property.charges}€ {!property.isLocation && "/ mois"})
                </span>
              )}{" "}
              {property.isLocation && <span>/ mois</span>}
            </div>

            <div className="data__info">
              <div className="data__peb info-container">
                <div className="svg-container">
                  <img src={peb_svg} alt="peb" className="peb-svg svg" />
                </div>
                {property.peb}
              </div>
              <div className="data__bedrooms info-container">
                <div className="svg-container">
                  <img
                    src={bed_svg}
                    alt="beds number"
                    className="bed-svg svg"
                  />
                </div>
                {property.nbBedrooms}
              </div>

              {property.outdoorsArea === 0 ? (
                <div className="data__badrooms info-container">
                  <div className="svg-container">
                    <img
                      src={bath_svg}
                      alt="bathrooms number"
                      className="bath-svg svg"
                    />
                  </div>
                  {property.nbBathrooms || 0}
                </div>
              ) : (
                <div className="data__outdoor info-container">
                  <div className="svg-container">
                    <img
                      src={outdoor_svg}
                      alt="outdoor area"
                      className="outdoor-svg svg"
                    />
                  </div>
                  {property.outdoorsArea}m²
                </div>
              )}
              <div className="data__indoor info-container">
                <div className="svg-container">
                  <img
                    src={indoor_svg}
                    alt="indoor area"
                    className="indoor-svg svg"
                  />
                </div>
                {property.indoorsArea}m²
              </div>
            </div>
            <div className="data__address info-container">
              <div className="svg-container">
                <img
                  src={location_svg}
                  alt="location"
                  className="location-svg svg"
                />
              </div>
              <p>
                {property.street} {property.number}
                {(property.street || property.number) && ","} {property.zip}{" "}
                {property.city}
              </p>
            </div>
            {changeShow && (
              <div className="property-card__buttons">
                <Button
                  buttonStyle={"btn--secondary"}
                  onClick={() => changeSold()}
                  buttonColor={"btn--dark"}
                  children={property.isLocation ? "loué" : "vendu"}
                ></Button>
                <Button
                  buttonStyle={"btn--secondary"}
                  onClick={changeOption}
                  buttonColor={"btn--dark"}
                  children={"Sous-option"}
                ></Button>
                <Button
                  buttonStyle={"btn--secondary"}
                  onClick={() => changeShow(property)}
                  buttonColor={"btn--dark"}
                  children={"Modifier"}
                ></Button>
                <Button
                  buttonStyle={"btn--secondary"}
                  onClick={() => confirm(property)}
                  buttonColor={"btn--dark"}
                  children={"Supprimer"}
                ></Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toast />
    </>
  );
};

export default Card;
