import React, { useState } from "react";
import logo from "./../assets/logo_only.png";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";

const NavBar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <div className="navbar">
        <div className="navbar-container container">
          <a
            href={`${process.env.REACT_APP_CLIENT_URL}/#home`}
            className="navbar-logo"
            onClick={closeMobileMenu}
          >
            <img src={logo} alt="NewPlace" className="logo-img" />
          </a>
          <div className="menu-icon" onClick={handleClick}>
            <div className="menu-line menu-line-top"></div>
            <div className="menu-line menu-line-middle"></div>
            <div className="menu-line menu-line-bottom"></div>
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a
                href={`/#home`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                ACCUEIL
              </a>
            </li>
            <li className="nav-item">
              <a
                href={`/#principles`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                PRINCIPES
              </a>
            </li>
            <li className="nav-item">
              <a
                href={`/#services`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                SERVICES
              </a>
            </li>
            <li className="nav-item">
              <p className="nav-links real-estate">NOS BIENS &darr;</p>
              <div className="nav-item__properties">
                <a
                  href={`/properties?type=sales`}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  À VENDRE
                </a>
                <a
                  href={`/properties?type=rentals`}
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  À LOUER
                </a>
              </div>
            </li>
            <li className="nav-item nav-contact">
              <a
                href={`/management`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                GESTION LOCATIVE
              </a>
            </li>
            <li className="nav-item nav-contact">
              <a
                href={`/contact`}
                className="nav-links"
                onClick={closeMobileMenu}
              >
                CONTACT
              </a>
            </li>
            <li className="nav-item social-media">
              <ul className="social-media__items">
                <li>
                  <a
                    href="https://www.facebook.com/newplaceimmo"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <AiFillFacebook />
                  </a>
                </li>
                <li>
                  <a
                    href="https://instagram.com/new_place_immo?igshid=YmMyMTA2M2Y="
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <AiFillInstagram />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NavBar;
