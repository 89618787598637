import React from "react";

const COLOR = ["btn--light", "btn--dark"];
const STYLES = ["btn--primary", "btn--secondary"];
const SIZES = ["btn--medium", "btn--large", "btn--mobile", "btn--wide"];

const Button = ({
  children,
  href,
  buttonStyle,
  buttonSize,
  buttonColor,
  onClick,
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];
  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
  const checkButtonColor = COLOR.includes(buttonColor) ? buttonColor : COLOR[0];

  return (
    <>
      <a
        className={`btn ${checkButtonColor} ${checkButtonStyle} ${checkButtonSize}`}
        href={href}
        onClick={onClick}
      >
        {children}
      </a>
    </>
  );
};

export default Button;
