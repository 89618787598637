import React from "react";

const Footer = () => {
  return (
    <div className="footer section">
      <div className="footer__data">
        <div className="footer__informations">
          <p>TVA : BE0786 837 571</p>
          <p>BCE : 07 8683 7571</p>
          <p>Autorisation : IPI 512 522</p>
          <p>Assurance : AXA 730.390.160</p>
        </div>
        <div className="footer__informations">
          <h4>Instance de contrôle</h4>
          <h4>IPI. Soumis au code déontologique de l'IPI</h4>
          <p>
            Rue du Luxembourg 16B
            <br />
            1000 Bruxelles - BELGIQUE
          </p>
        </div>
        <div className="footer__address">
          <h2>New Place</h2>
          <p>+32 470 123 778</p>
          <p>
            Chaussée d'Ottenbourg 9<br />
            1300 Wavre
          </p>
        </div>
      </div>
      <div className="footer__mentions">
        <div className="footer__links">
          <a
            href="https://www.flaticon.com/animated-icons"
            title="animated icons"
          >
            Icons made by <span>Freepik</span> from{" "}
            <span>www.flaticon.com</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
