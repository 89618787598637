import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState({
    isAuthenticated: false,
    details: {},
  });

  async function login(data) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/login`, data).then((res) => {
      if (res.data.message === "Success") {
        setUser({
          isAuthenticated: true,
          details: res.data.user,
        });
        setAuthorizationToken(res.data.token);
        return res.data.message;
      }
    });
  }

  function logout() {
    setUser({
      isAuthenticated: false,
      details: {},
    });
    localStorage.removeItem("token");
  }
  useEffect(() => {
    const fetchUser = async () => {
      let token = localStorage.getItem("token");
      if (!token) {
        setLoading(false);
        return;
      }
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) logout();
      else {
        axios.defaults.headers.common["Authorization"] = token;
        await axios
          .get(`${process.env.REACT_APP_SERVER_URL}/auth/reconnect`)
          .then((res) => {
            if (res.data.message === "Success") {
              setUser({
                isAuthenticated: true,
                details: res.data.user,
              });
              setLoading(false);
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            logout();
          });
      }
    };

    fetchUser();
  }, []);

  const value = {
    user,
    login,
    logout,
    isAuthenticated: user.isAuthenticated,
    isLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const setAuthorizationToken = (token) => {
  localStorage.setItem("token", `Bearer ${token}`);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};
