import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Card from "../Card";
import axios from "axios";
import Toast from "../Toast";
import { toast } from "react-toastify";
import NavBar from "../NavBar";
import Footer from "./Footer";
import MiniCard from "../MiniCard";
import bckg_map from "./../../assets/bckg_section.png";

const Properties = () => {
  const [properties, setProperties] = useState(null);
  const [isMobile, SetIsMobile] = useState(false);

  const changeVertical = () => {
    if (window.innerWidth <= 960) {
      SetIsMobile(true);
    } else {
      SetIsMobile(false);
    }
  };

  useEffect(() => {
    changeVertical();
    window.addEventListener("resize", changeVertical);
  });

  const fetchProperties = (type) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/property/${
          type == "sales" ? "sales" : "rentals"
        }`,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => res.data.properties && setProperties(res.data.properties))
      .catch((err) => {
        toast.error(err.response.data.message, { className: "toast-message" });
      });
  };

  useEffect(() => {
    const type = new URLSearchParams(window.location.search).get("type");
    fetchProperties(type);
  }, []);

  const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow: 1,
    speed: 500,
  };

  return (
    <>
      <NavBar />
      <div className="section" id="properties">
        <img src={bckg_map} className="background-image contact-img" alt="" />
        <div className="properties">
          {properties?.length > 0 ? (
            properties &&
            properties.map((property) => <MiniCard property={property} />)
          ) : (
            <div className="properties__empty">
              Il n'y a actuellement aucun bien disponible.
              <br />
              Revenez plus tard... :-)
            </div>
          )}
        </div>
      </div>
      <Footer />
      <Toast />
    </>
  );
};

export default Properties;
