import React from "react";
import Slider from "react-slick";
import private_property from "./../../assets/private_property_2.jpg";

const Principles = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true
  };

  return (
    <div className="section" id="principles">
      {/* <img src={bckg_map} className="background-image services-img" alt="" /> */}
      <div className="principles">
        <div className="principles__slider principles__half">
          <div>
            <Slider {...settings}>
              <div className="principles__card">
                <h3>Expertise.</h3>
                <br />
                <p>
                  Nous vivons dans un monde en constante évolution et ce qui
                  était bon hier ne suffira pas demain. Au-delà de notre
                  formation, nous nous entourons également de personnes
                  qualifiées de tous horizons (juridiques, fiscal, bancaire,
                  etc).
                  <br />
                  <br />
                  Notre expertise permettra à votre bien d’être présenté, sous
                  son meilleur jour, au plus grand nombre d’acheteurs
                  potentiels.
                </p>
              </div>
              <div className="principles__card">
                <h3>Respect et transparence.</h3>
                <br />
                <p>
                  La profession d’agent immobilier est une profession
                  réglementée, soumise à des règles déontologiques édictées par
                  l’Institut Professionnel des Agents Immobiliers (IPI). Nous
                  sommes également assurés en responsabilité civile
                  professionnelle et nous suivons des formations obligatoires
                  chaque année.
                  <br />
                  <br />
                  Nous pensons également qu’il est essentiel de maintenir une
                  relation avec nos clients sur le long terme. C’est pourquoi
                  nous mettons l’intégrité et le respect en avant pour
                  contribuer à un environnement positif.
                </p>
              </div>
              <div className="principles__card">
                <h3>Passion et engagement.</h3>
                <br />
                <p>
                  Répondre aux besoins de nos clients est notre plus grande
                  satisfaction. Il est important de vous comprendre et dépasser
                  vos attentes. De cette façon, nous arrivons à satisfaire les
                  besoins de toutes les parties.
                </p>
              </div>
              <div className="principles__card">
                <h3>Teamwork</h3>
                <br />
                <p>
                  New Place adopte une approche collaborative. Aucune demande
                  n’est trop grande et nous sommes disponibles 7j/7 pour vous
                  mais aussi pour les candidats acquéreurs.
                </p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="principles__image principles__half">
          <img className="img private_property" src={private_property} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Principles;
