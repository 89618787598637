import React, { useState } from "react";
import Button from "../Button";
import private_property from "./../../assets/private_property_2.jpg";
import logo from "./../../assets/logo.png";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { toast } from 'react-toastify';
import Toast from "../Toast";

const ConnectAdmin = () => {
  const { login } = useAuth();

  const [email, setEmail] = useState('');

  const [showForm, setShowForm] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const submitLogin = () => {
    login(credentials)
      .catch((err) => {
        toast.error(err.response.data.message, {className:"toast-message"})
      });
  };

  const sendMail = () => {
    axios.post(`${process.env.REACT_APP_SERVER_URL}/password`, {email})
      .then((res) => toast.success(res.data.message,  {className:"toast-message"}))
      .catch((err) => toast.error(err.response.data.message,  {className:"toast-message"}));

    setShowPwd(false);
  }

  return (
    <div className="admin-connect">
      <img className="img private_property" src={private_property} alt="" />
      <div className="admin-connect__data">
        <h3>Vous n'avez rien à faire ici !</h3>
        <div className="admin-connect__data-buttons">
          <Button buttonColor={'btn--dark'} buttonStyle={'btn--secondary'} href="/" children={"Retour vers le site"} />
          <Button
            buttonColor={'btn--dark'} 
            buttonStyle={'btn--secondary'}
            onClick={() => setShowForm(true)}
            children={"Portail administratif"}
          />
        </div>
      </div>
      <div
        onClick={() => setShowForm(false)}
        className={
          showForm
            ? "admin-connect__form-overlay active"
            : "admin-connect__form-overlay"
        }
      >
        <div className="admin-connect__modal">
          <div className="admin-connect__img" onClick={(e) => e.stopPropagation()}>
            <img className="logo-img" src={logo} alt="" />
          </div>
          <div
            onClick={(e) => e.stopPropagation()}
            className="admin-connect__form"
          >
            <div className="sign-up-form">
              <div className="input">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  className="input-data"
                  onChange={handleChange}
                />
              </div>
              <div className="input">
                <label htmlFor="password">Mot de passe</label>
                <input
                  type="password"
                  name="password"
                  className="input-data"
                  onChange={handleChange}
                />
              </div>
              <div className="sign-up-form__buttons">
                <Button
                  onClick={() => setShowForm(false)}
                  buttonStyle={"btn--outline"}
                  buttonSize={"btn--medium"}
                  children={"Exit"}
                />
                <Button
                  buttonStyle={"btn--outline"}
                  buttonSize={"btn--medium"}
                  children={"Se connecter"}
                  onClick={submitLogin}
                />
              </div>
              <div className="sign-up-form__forgot-pwd" onClick={() => setShowPwd(true)}>
                Mot de passe oublié ?
              </div>
            </div>
          </div>
          { showPwd && <div className="admin-connect__forgot-pwd" onClick={(e) => {
            e.stopPropagation()
            setShowPwd(false)
          }}
            >
              <div className="admin-connect__forgot-pwd-mail" onClick={(e) => e.stopPropagation()}>
                <div className="admin-connect__input">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    className="input-data"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="admin-connect__buttons">
                  <Button onClick={() => setShowPwd(false)} buttonStyle={'btn--secondary'} buttonColor={'btn--dark'} children={'retour'} />
                  <Button onClick={() => sendMail()} buttonStyle={'btn--secondary'} buttonColor={'btn--dark'} children={'confirmer'} />
                </div>
              </div>
          </div>}
        </div>
      </div>
      <Toast />
    </div>
  );
};

export default ConnectAdmin;
