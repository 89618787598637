import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import Card from "../Card";
import { IoMdAddCircleOutline } from 'react-icons/io';
import { AiOutlineLogout } from 'react-icons/ai'
import FormProperty from "../FormProperty";
import Button from './../Button';
import { useAuth } from "./../../contexts/AuthContext";
import Toast from "../Toast";
import { toast } from 'react-toastify';

const AdminPage = () => {
  const [properties, setProperties] = useState(null);
  const [currentProperty, setCurrentProperty] = useState()
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const {logout} = useAuth();

  const [confirm, setConfirm] = useState(false);

  const deleteProperty = () => {
    axios
        .delete(`${process.env.REACT_APP_SERVER_URL}/property/${currentProperty._id}`)
        .then((res) => {
          fetchProperties();
          setConfirm(false);
          toast.success(res.data.message, {className:"toast-message"})
        })
        .catch((err) => {
          toast.error('Erreur lors de la suppression', {className:"toast-message"})
        })
  }

  const fetchProperties = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/property/`)
      .then(
        (res) => res.data.properties && setProperties(res.data.properties)
      );
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    if(!update) return;
    fetchProperties();
    setUpdate(false);
  }, [update])

  const [isVertical, setIsVertical] = useState(false);

  const changeVertical = () => {
    if (window.innerWidth <= 960) {
      setIsVertical(true);
    } else {
      setIsVertical(false);
    }
  };

  useEffect(() => {
    changeVertical();
    window.addEventListener("resize", changeVertical);
  });

  const addShow = (property) => {
    setCurrentProperty(property)
    setShow(true);
  }

  const askConfirm = (property) => {
    setConfirm(true);
    setCurrentProperty(property);
  }

  const removeShow = () => {
    setShow(false);
  }

  const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    verticalSwiping: false,
    slidesToShow: 1,
    vertical: false,
    speed: 500,
  };

  return (
    <div className="section admin-page" id="admin-page" style={{ position: "relative" }}>
      <div className="admin-page__options"><span className="text" onClick={logout}><AiOutlineLogout /></span></div>
      {confirm && <div className="confirm-modal__overlay" onClick={() => setConfirm(false)}>
                    <div className="confirm-modal" onClick={(e) => e.stopPropagation()}>
                        <div className="confirm-modal__sentence">
                            Êtes-vous sûr de vouloir supprimer cette propriété ?
                        </div>
                        <div className="confirm-modal__buttons">
                            <Button onClick={() => setConfirm(false)} buttonStyle={'btn--secondary'} buttonColor={'btn--light'} children={'non'} />
                            <Button onClick={() => deleteProperty()} buttonStyle={'btn--secondary'} buttonColor={'btn--light'} children={'oui'} />
                        </div>
                    </div>
                </div>}
      <div className="admin-page__content">
        <Slider arrows={!isVertical} {...settings}>
          {properties &&
            properties.map((property, idx) => <Card key={idx} property={property} update={setUpdate} changeShow={addShow} confirm={askConfirm} />)}
          <div className="add-property">
            <div onClick={() => addShow(null)}><IoMdAddCircleOutline /></div>
          </div>
        </Slider> 
          
        {show && (
          <FormProperty property={currentProperty} update={setUpdate} removeShow={removeShow} />
        )}
      </div>
      <Toast />
    </div>
  );
};

export default AdminPage;
